import React from "react";

const IconLogo = () => (
  <svg
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M484.328 169H646.328L873.328 799H712.328L669.328 664H457.328L414.328 799H255.328L484.328 169ZM490.328 554H634.328L610.328 478C602.328 453.333 593.995 427 585.328 399C577.328 371 570.328 345.333 564.328 322H562.328C555.661 345.333 547.995 370.667 539.328 398C530.661 425.333 522.328 452 514.328 478L490.328 554Z"
        fill="#CCD6F6"
      />
      <path
        d="M466 178L239 790H167L402 178H466Z"
        stroke="#CCD6F6"
        stroke-width="18"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1000" height="1000" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLogo;
