import React from "react";

const IconLoader = () => (
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  //   <title>Loader Logo</title>
  //   <g>
  //     <g id="B" transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //     </g>
  //     <path
  //       stroke="currentColor"
  //       strokeWidth="5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       d="M 50, 5
  //                 L 11, 27
  //                 L 11, 72
  //                 L 50, 95
  //                 L 89, 73
  //                 L 89, 28 z"
  //     />
  //   </g>
  // </svg>

  <svg
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M484.328 169H646.328L873.328 799H712.328L669.328 664H457.328L414.328 799H255.328L484.328 169ZM490.328 554H634.328L610.328 478C602.328 453.333 593.995 427 585.328 399C577.328 371 570.328 345.333 564.328 322H562.328C555.661 345.333 547.995 370.667 539.328 398C530.661 425.333 522.328 452 514.328 478L490.328 554Z"
        fill="#CCD6F6"
      />
      <path
        d="M466 178L239 790H167L402 178H466Z"
        stroke="#CCD6F6"
        stroke-width="18"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1000" height="1000" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLoader;
