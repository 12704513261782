module.exports = {
  email: "alejandro@arusoftware.com",

  socialMedia: [
    {
      name: "GitHub",
      url: "https://github.com/Alejandro99aru"
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/alejandro_aru/"
    },
    {
      name: "Twitter",
      url: "https://twitter.com/alejandro99aru"
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/alejandro-r-14a773123/"
    }
  ],

  navLinks: [
    {
      name: "Sobre mi",
      url: "/#about"
    },
    {
      name: "Experiencia",
      url: "/#jobs"
    },
    {
      name: "Proyectos",
      url: "/#projects"
    },
    {
      name: "Contacto",
      url: "/#contact"
    }
  ],

  colors: {
    green: "#5564eb",
    navy: "#0a192f",
    darkNavy: "#020c1b"
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: "bottom",
    distance: "20px",
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    mobile: true,
    reset: false,
    useDelay: "always",
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 }
  })
};
